import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link as GatsbyLink } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Title text="Memberships" mdxType="Title">Professional</Title>
    <ul>
      <li parentName="ul">
        <GatsbyLink to="/memberships/bcs" mdxType="GatsbyLink">BCS, The Chartered Institute for IT</GatsbyLink>
      </li>
    </ul>
    <Title text="Lifelong Learning" mdxType="Title">Continuing Professional Development</Title>
    <ul>
      <li parentName="ul">
        <GatsbyLink to="/lifelong-learning/kafka" mdxType="GatsbyLink">Apache Kafka</GatsbyLink>
      </li>
      <li parentName="ul">
        <GatsbyLink to="/lifelong-learning/aws" mdxType="GatsbyLink">Amazon Web Services (AWS)</GatsbyLink>
      </li>
      <li parentName="ul">
        <GatsbyLink to="/lifelong-learning/gcp" mdxType="GatsbyLink">Google Cloud Platform (GCP)</GatsbyLink>
      </li>
      <li parentName="ul">
        <GatsbyLink to="/lifelong-learning/scala" mdxType="GatsbyLink">Scala</GatsbyLink>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      