import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hello!`}</p>
    <p>{`I'd like to share some material on the science, art and craft of software engineer with you.`}</p>
    <p>{`I've been helping to deliver solutions since the last days of the last millenium. Sometimes solo but most
often in teams using open source, agile and lean methodologies.`}</p>
    <p>{`This of this not as a shop window. There are many fine blogs offering polished posts.`}</p>
    <p>{`But as a door left ajar to give a little glimpse into my workshop.`}</p>
    <p><em parentName="p">{`Robert Burrell Donkin`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      